import forEach from '../../../../javascripts/dom/forEach';

let eventTicketLists = document.querySelectorAll('[data-js-module="o-event-ticket-list"]');

const init = async () => {
  const { default: EventTicketList } = await import(
    /* webpackChunkName: "o-event-ticket-list" */ './event-ticket-list.jsx'
  );

  forEach(eventTicketLists, (index, element) => new EventTicketList(element));
};

window.addEventListener('Manual.init', e => {
  eventTicketLists = document.querySelectorAll('[data-js-module="o-event-ticket-list"]');
  if (eventTicketLists.length) init();
});

if (eventTicketLists.length) init();
